@import "core/style/style";

#header-container {
  display: flex;
  justify-content: space-between;
  padding-left: @spacing-md;

  .return-button {
    margin-left: -6px;
    font-size: var(--font-size-sm);
    font-weight: 600;
    padding-right: @spacing-md;
    padding-left: 0px;
    background-color: transparent;
    box-shadow: none;
    border: none;
    

    &:hover {
      text-decoration: underline;
    }

    .button-text {
      max-height: 18px;
    }

    .icon-left {
      margin-right: @spacing-sm;
    }
  }
}
