@import "core/style/settings";

#authorized-payment-screen {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .authorized-screen-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 16px;
    margin-right: 16px;

    .header {
      margin-top: @spacing-lg;
      width: 100%;
      max-width: var(--status-screen-max-width, 750px);
    }

    .content {
      display: flex;
      align-items: center;
      flex-direction: column;
      max-width: var(--status-screen-max-width, 750px);
      width: 100%;

      .title {
        margin-top: @spacing-xs;
        font-size: var(--font-size-xl);
        font-weight: 500;
        color: var(--primary-color, @text-color-black);
        text-align: center;
      }

      .description {
        margin-top: @spacing-md;
        font-size: var(--font-size-md);
        font-weight: 400;
        color: var(--primary-color, @color-near-white);
        text-align: center;
      }

      .box {
        margin-top: @spacing-lg;
        margin-bottom: @spacing-md;
        width: 100%;
        background: var(--box-background, @information-screen-background)
          no-repeat center/cover;
        border: var(--box-border, 1px solid @color-grey);
        border-radius: var(--box-border-radius, var(--border-radius, 6px));
        padding: 40px;
        padding-bottom: 30px;

        .header-container {
          display: flex;
          width: 100%;
          justify-content: space-between;
          margin-bottom: @spacing-md;

          .header {
            width: 100%;
            display: flex;

            .box-content {
              display: flex;
              width: 100%;
              flex-direction: column;
              justify-content: space-between;

              .box-header {
                display: flex;
                justify-content: space-between;
              }

              .date {
                display: flex;
                align-items: center;
                font-size: var(--font-size-md);

                .date-icon {
                  margin-right: @spacing-xs;
                  path {
                    stroke: black;
                  }
                }
              }
            }

            .image {
              width: 160px;
              height: 120px;
              object-fit: cover;
              border-radius: var(--border-radius, 8px);
              margin-right: @spacing-sm;
            }

            .title {
              text-align: left;
              margin-top: 0px;
              font-size: var(--font-size-lg);
              font-weight: 400;
              color: var(--box-color, var(--primary-color, @text-color-black));
            }

            .price {
              margin-top: 0px;
              font-size: var(--font-size-lg);
              font-weight: 400;
              color: var(--box-color, var(--primary-color, @text-color-black));
              white-space: nowrap;
              margin-left: @spacing-md;
            }

            .description-container {
              display: flex;
              margin-top: @spacing-sm;
              margin-bottom: @spacing-sm;

              .description {
                color: var(
                  --box-color,
                  var(--primary-color, @text-color-black)
                );
                margin-top: 0px;
              }
            }

            .description {
              text-align: left;
              margin-top: @spacing-xs;
              margin-bottom: @spacing-xs;
              font-size: var(--font-size-sm);
              font-weight: 500;
              color: var(--box-color, var(--primary-color, @text-color-black));
            }
          }
        }

        .seperator {
          width: 100%;
          border-top: var(--box-seperator, 2px solid @color-grey);
        }

        .content {
          display: flex;
          flex-direction: column;
          margin: 0px;
          margin-top: @spacing-lg;
          width: 100%;

          .item {
            width: 100%;
            display: flex;
            justify-content: space-between;
            margin-bottom: @spacing-xs;

            .label {
              font-size: var(--font-size-sm);
              font-weight: 400;
              color: var(--box-color, var(--primary-color, @text-color-black));
            }

            .value {
              font-size: var(--font-size-sm);
              font-weight: 400;
              color: var(--box-color, var(--primary-color, @text-color-black));
            }

            &:last-child {
              margin-bottom: @spacing-lg;
            }
          }
        }

        .footer {
          margin-top: @spacing-md;

          .item {
            display: flex;
            width: 100%;
            justify-content: space-between;

            .label {
              font-size: var(--font-size-lg);
              font-weight: 400;
              color: var(--box-color, var(--primary-color, @text-color-black));
            }

            .value {
              font-size: var(--font-size-lg);
              font-weight: 400;
              color: var(--box-color, var(--primary-color, @text-color-black));
            }
          }
        }
      }
    }
  }

  .footer-container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .footer {
      display: flex;
      justify-content: space-between;
      padding-top: @spacing-md;
      margin-top: @spacing-sm;
      margin-bottom: 32px;
      margin-right: 16px;
      margin-left: @spacing-md;
      align-items: flex-end;

      .footer-content {
        display: flex;
        align-items: center;
        color: var(--primary-color, @text-color-black);
        font-size: var(--font-size-xs);
        font-weight: 400;

        .seperator {
          width: 1px;
          height: 12px;
          background-color: var(--primary-color, @text-color-black);
          margin-left: @spacing-xs;
          margin-right: @spacing-xs;
        }

        .powered-by svg {
          margin-left: @spacing-xs;
        }

        .links {
          margin-right: @spacing-xs;
          cursor: pointer;

          &:hover {
            text-decoration: underline;
          }

          &.active {
            cursor: default;
            text-decoration: underline;
          }
        }
      }
    }

    .security-icon {
      width: 65px;
      height: 65px;
      margin-top: 0px;
      margin-right: 1.5rem;
    }
  }
}

@media only screen and (max-width: 800px) {
  #authorized-payment-screen {
    .authorized-screen-container {
      height: 100%;

      .header {
        margin-right: 0px;
      }

      .content {
        width: 100%;
        margin-top: @spacing-md;
        margin-left: @spacing-md;
        margin-right: @spacing-md;
        margin-bottom: 0px;

        .title {
          margin-top: 0px;
        }

        .description {
          margin-top: @spacing-sm;
        }

        .box {
          padding: 20px;
          padding-bottom: 20px;
        }
      }
    }

    .footer-container {
      .footer {
        margin-left: 16px;
        margin-right: 16px;
      }

      .security-icon {
        margin-right: 16px;
      }
    }
  }
}

@media only screen and (max-width: 750px) {
  #authorized-payment-screen {
    .security-icon {
      display: none;
    }
  }
}

@media only screen and (max-width: 500px) {
  #authorized-payment-screen {
    .authorized-screen-container {
      .content {
        .box {
          .header-container {
            .header {
              .image {
                width: 100px;
                height: 100px;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 400px) {
  #authorized-payment-screen {
    .footer-container {
      .footer {
        display: flex;
        flex-direction: column;

        .footer-content {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          margin-bottom: 0px;

          .seperator {
            display: none;
          }

          .links {
            margin-top: 0.25rem;
            margin-right: 0.25rem;
            margin-left: 0px;
            cursor: pointer;
          }
        }
      }
    }
  }
}
