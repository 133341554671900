@import "core/style/settings";

#language-select-component {
  position: relative;

  .select-box {
    display: flex;
    align-items: center;
    height: 45px;
    font-size: var(--font-size-sm);
    font-weight: 600;
    cursor: pointer;
    user-select: none;

    .selected-value {
      display: flex;
      align-items: center;
      margin-left: @spacing-sm;
    }

    .flag-icon {
      margin-left: @spacing-sm;
      height: 20px;
    }

    .dropdown-icon {
      margin-left: @spacing-md;
      margin-right: @spacing-sm;

      &.open {
        transform: rotate(180deg);
      }
    }

    &.open {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  .options-list {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    border-top: none;
    z-index: 1000;
    overflow-y: auto;

    .option-item {
      display: flex;
      align-items: center;
      height: 45px;
      cursor: pointer;
      font-size: var(--font-size-sm);
      font-weight: 600;

      .value {
        display: flex;
        align-items: center;
        margin-left: @spacing-sm;
      }

      .flag-icon {
        margin-left: @spacing-sm;
        height: 20px;
      }
    }
  }
}
