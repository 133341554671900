@import "core/style/settings";

#default-layout {
  .default-layout-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    max-width: var(--container-max-width, 1200px);
    margin: 0 auto;

    .main-container {
      display: flex;
      flex-grow: 1;
      height: 100%;
      background: var(--primary-background) no-repeat center/cover;

      .informations-screen-container {
        width: 50%;
      }

      .header {
        padding-right: @spacing-md;
      }

      .content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;
        padding-top: @spacing-lg;
        padding-bottom: @spacing-lg;
        max-width: 600px;
        margin: 0 auto;
        flex-grow: 1;

        &.fixed {
          position: fixed;
          height: 100%;
        }

        .content-container {
          display: flex;
          flex-direction: column;

          &.has-information-screen {
            height: 100%;
            justify-content: center;
          }

          .information-container {
            display: flex;
            justify-content: space-between;
            padding: var(--information-container-padding, @spacing-sm);
            margin: var(
              --information-container-margin,
              32px @spacing-md 0 @spacing-md
            );
            border-radius: var(--border-radius, 6px);
            background: var(
                --secondary-background,
                @information-screen-background
              )
              no-repeat center/cover;

            .logo-container {
              max-width: var(--information-container-logo-max-width, 130px);
              max-height: var(--information-container-logo-max-height, 130px);
              width: 100%;
              height: auto;
              overflow: hidden;
              display: flex;
              justify-content: left;
              align-items: center;
              margin-right: @spacing-md;
            }

            .sum-container {
              display: flex;
              justify-content: flex-end;

              .sum-label {
                text-align: right;
                font-size: var(--font-size-sm);
                color: var(--secondary-color, @text-color-black);
              }

              .sum {
                font-size: var(--font-size-xxl);
                font-weight: 700;
                color: var(--secondary-color, @text-color-black);
              }
            }
          }
        }

        .footer {
          display: flex;
          justify-content: space-between;
          padding-top: @spacing-md;
          margin-top: @spacing-sm;
          padding-right: @spacing-md;
          padding-left: @spacing-md;
          align-items: flex-end;

          .footer-content {
            display: flex;
            align-items: center;
            color: var(--primary-color, @text-color-black);
            font-size: var(--font-size-xs);
            font-weight: 400;

            .seperator {
              width: 1px;
              background-color: var(--primary-color, @text-color-black);
              height: 12px;
              margin-left: @spacing-xs;
              margin-right: @spacing-xs;
            }

            .powered-by svg {
              margin-left: @spacing-xs;
            }

            .links {
              margin-right: @spacing-xs;
              cursor: pointer;

              &:hover {
                text-decoration: underline;
              }
            }
          }
        }
      }
    }
  }

  .security-icon {
    display: flex;
    justify-content: flex-end;
    margin-top: calc(-65px - 1.5rem);
    margin-right: 1.5rem;
  }
}

@media only screen and (max-width: 1100px) {
  #default-layout {
    .default-layout-container {
      .main-container {
        flex-direction: column;

        .informations-screen-container {
          width: 100%;
        }

        .header {
          margin-top: @spacing-md;
          margin-bottom: @spacing-md;
          margin-left: @spacing-md;
          margin-right: @spacing-md;
          padding-right: 0px;
          padding-left: 0px;
        }

        .content {
          padding-left: @spacing-md;
          padding-right: @spacing-md;

          .content-container {
            .information-container {
              margin-top: 0px;
              margin-left: 0px;
              margin-right: 0px;
            }
          }

          .footer {
            padding-left: 0px;
            padding-right: 0px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 750px) {
  #default-layout {
    .security-icon {
      background-color: red;
      display: none;
    }
  }
}

@media only screen and (max-width: 400px) {
  #default-layout {
    .default-layout-container {
      .main-container {
        .content {
          .footer {
            display: flex;
            flex-direction: column;
            align-items: center;

            .footer-content {
              display: flex;
              flex-direction: column;
              align-items: center;
              margin-bottom: 0px;

              .seperator {
                display: none;
              }

              .links {
                margin-top: 0.25rem;
                margin-right: 0.25rem;
                margin-left: 0px;

                cursor: pointer;
              }
            }
          }
        }
      }
    }
  }
}
