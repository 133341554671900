@import "core/style/settings";

#invalid-link-screen {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;

  .header {
    margin-top: @spacing-lg;
    padding-left: 0px;
    width: 100%;
    max-width: var(--status-screen-max-width, 750px);
  }

  .invalid-screen-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    align-items: center;
    background: transparent;

    .content {
      display: flex;
      align-items: center;
      flex-direction: column;
      max-width: var(--status-screen-max-width, 750px);
      margin-right: @spacing-md;
      margin-left: @spacing-md;

      .title {
        margin-top: @spacing-xs;
        font-size: var(--font-size-xl);
        font-weight: 500;
        color: var(--primary-color, @text-color-black);
        text-align: center;
      }

      .description {
        margin-top: @spacing-md;
        font-size: var(--font-size-md);
        font-weight: 400;
        color: var(--primary-color, @color-near-white);
        text-align: center;
      }
    }
  }

  .footer-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    .footer {
      display: flex;
      justify-content: space-between;
      padding-top: @spacing-md;
      margin-top: @spacing-sm;
      margin-bottom: 32px;
      margin-right: 16px;
      margin-left: @spacing-md;
      align-items: flex-end;

      .footer-content {
        display: flex;
        align-items: center;
        color: var(--primary-color, @text-color-black);
        font-size: var(--font-size-xs);
        font-weight: 400;

        .seperator {
          width: 1px;
          height: 12px;
          background-color: var(--primary-color, @text-color-black);
          margin-left: @spacing-xs;
          margin-right: @spacing-xs;
        }

        .powered-by svg {
          margin-left: @spacing-xs;
        }

        .links {
          margin-right: @spacing-xs;
          cursor: pointer;

          &:hover {
            text-decoration: underline;
          }

          &.active {
            cursor: default;
            text-decoration: underline;
          }
        }
      }
    }

    .security-icon {
      width: 65px;
      height: 65px;
      margin-top: 0px;
      margin-right: 1.5rem;
    }
  }
}

@media only screen and (max-width: 800px) {
  #invalid-link-screen {
    .header {
      margin-right: 0px;
      padding-right: 16px;
    }
    .invalid-screen-container {
      .content {
        margin-right: 16px;
        margin-left: 16px;

        .title {
          margin-top: 0px;
        }

        .description {
          margin-top: @spacing-sm;
        }
      }
    }

    .footer-container {
      .footer {
        margin-left: 16px;
        margin-right: 16px;
      }

      .security-icon {
        margin-right: 16px;
      }
    }
  }
}

@media only screen and (max-width: 750px) {
  #invalid-link-screen {
    .security-icon {
      display: none;
    }
  }
}

@media only screen and (max-width: 400px) {
  #invalid-link-screen {
    .footer-container {
      .footer {
        display: flex;
        flex-direction: column;

        .footer-content {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          margin-bottom: 0px;

          .seperator {
            display: none;
          }

          .links {
            margin-top: 0.25rem;
            margin-right: 0.25rem;
            margin-left: 0px;

            cursor: pointer;
          }
        }
      }
    }
  }
}
