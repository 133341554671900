@import "core/style/settings";

#redirect-screen {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;

  .header {
    margin-top: @spacing-lg;
    padding-left: 0px;
    width: 100%;
    max-width: var(--status-screen-max-width, 750px);
  }

  .redirect-screen-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    align-items: center;
    background: var(--background, #fff);

    .content {
      display: flex;
      align-items: center;
      flex-direction: column;
      max-width: var(--status-screen-max-width, 750px);
      margin-right: @spacing-md;
      margin-left: @spacing-md;

      .title {
        font-size: var(--font-size-xl);
        font-weight: 500;
        color: var(--primary-color, @text-color-black);
        text-align: center;
        margin-top: 64px;
      }

      .description {
        margin-top: @spacing-md;
        font-size: var(--font-size-md);
        font-weight: 400;
        color: var(--primary-color, @color-near-white);
        text-align: center;
      }

      .loading-spinner {
        width: 40px;
        height: 40px;
        position: relative;
        margin-top: 64px;

        &:after,
        &:before {
          animation: rotate 1s cubic-bezier(0.785, 0.135, 0.15, 0.86) infinite;
          border: 4px solid transparent;
          border-radius: 50%;
          content: "";
          display: block;
          height: 100%;
          left: 0;
          position: absolute;
          top: 0;
          width: 100%;
        }

        &:after {
          border-color: transparent;
          border-top-color: var(--primary-color, @color-black);
        }

        &:before {
          border-color: var(--primary-color, @color-black);
          border-top-color: transparent;
          opacity: 0.1;
        }
      }
    }
  }

  .footer-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    .footer {
      display: flex;
      justify-content: space-between;
      margin-top: @spacing-xl;
      padding-top: @spacing-sm;
      margin-bottom: 32px;
      margin-right: 16px;
      margin-left: @spacing-md;
      align-items: flex-end;

      .footer-content {
        display: flex;
        align-items: center;
        color: var(--primary-color, @text-color-black);
        font-size: var(--font-size-xs);
        font-weight: 400;
      }
    }
  }
}

@media only screen and (max-width: 800px) {
  #redirect-screen {
    .header {
      margin-right: 0px;
      padding-right: 16px;
    }

    .redirect-screen-container {
      .content {
        margin-right: 16px;
        margin-left: 16px;

        .title {
          margin-top: 32px;
        }

        .description {
          margin-top: @spacing-sm;
        }

        .loading-spinner {
          margin-top: 64px;
        }
      }
    }

    .footer-container {
      .footer {
        margin-left: 16px;
        margin-right: 16px;
      }
    }
  }
}

@media only screen and (max-width: 400px) {
  #redirect-screen {
    .redirect-screen-container {
      .content {
        .title {
          margin-top: 32px;
        }

        .description {
          margin-top: @spacing-sm;
        }

        .loading-spinner {
          margin-top: 64px;
        }
      }
    }
  }
}
