@import "core/style/settings";

#bank-transfer-screen {
  display: flex;
  flex-direction: column;
  align-items: center;

  .card {
    height: 200px;
    margin-top: @spacing-md;
    margin-bottom: @spacing-lg;
    display: var(--card, block);
  }

  .form-container {
    width: 100%;

    .iban-container {
      display: flex;
      align-items: flex-end;
      gap: @spacing-sm;

      .iban-input {
        flex-grow: 1;
        min-width: 280px;
      }

      .button-container {
        display: flex;
        gap: @spacing-xs;
        max-width: 40%;
        width: 100%;
        margin-bottom: 16px;

        .is-expanded {
          flex-grow: 1;
        }
      }
    }

    .form-input-container {
      display: flex;
      gap: @spacing-sm;
      margin-top: @spacing-sm;
      margin-bottom: @spacing-md;
    }
  }

  .pay-now-button {
    margin-top: @spacing-md;
    font-weight: 600;
  }
}

@media only screen and (max-width: 1500px) {
  #bank-transfer-screen {
    .form-container {
      .iban-container {
        flex-direction: column;
        gap: 0;
        width: 100%;
      }
    }

    .pay-now-button {
      position: sticky;
      bottom: @spacing-sm;
      margin-top: @spacing-md;
      margin-bottom: @spacing-md;
    }

    .button-container {
      display: flex;
      margin-top: @spacing-md;
      gap: @spacing-sm;
      width: 100%;
    }
  }
}

@media only screen and (max-width: 750px) {
  #bank-transfer-screen {
    .form-container {
      .form-input-container {
        flex-direction: column;
        gap: @spacing-sm;
      }
    }

    .button-container {
      flex-direction: column;
    }
  }
}

@media only screen and (max-height: 800px) and (min-width: 1100px) {
  #bank-transfer-screen {
    .form-container {
      margin-top: @spacing-lg;
    }

    // Hide card svg if screen height is less than 800px
    .card {
      display: none
    }
  }
}
