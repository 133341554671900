// Styles for the adyen checkout plugin
@import "core/style/settings";

// All fields required information
.adyen-checkout-form-instruction {
  display: none;
}

// Loading spinner styles
.adyen-checkout__spinner.adyen-checkout__spinner--large {
  height: 40px;
  width: 40px;
  border-top-color: transparent;
}

// Pay Button
.adyen-checkout__button.adyen-checkout__button--pay {
  width: 100%;
  background: var(--pay_button-background, @color-black);
  border: var(--pay_button-border, 1px solid @color-brown);
  box-shadow:
    0px 0px 15px 1px #ffffff66 inset,
    4px 12px 26px 0px rgba(0, 0, 0, 0.3);
  border-radius: var(--pay_button-border-radius, var(--border-radius, 8px));

  .adyen-checkout__button__text {
    font-size: var(--font-size-md);
    font-weight: 600;
    color: var(--pay_button-color, @color-white);
  }

  &:disabled {
    background-color: var(--pay_button-disabled-background, #6e6e6e);
    box-shadow: none;
    cursor: not-allowed;
    opacity: 100%;

    .adyen-checkout__button__text {
      font-size: var(--font-size-md);
      font-weight: 600;
      color: var(
        --pay_button-disabled-color,
        var(--pay_button-color, @color-white)
      );
    }
  }

  .adyen-checkout__spinner {
    height: 20px;
    width: 20px;
    border-top-color: transparent;
  }
}

.adyen-checkout__spinner:after {
  border-top-color: var(--primary-color, @color-black);
}

.adyen-checkout__spinner:before {
  border-color: var(--primary-color, @color-black);
  border-top-color: transparent;
}

// Labels
.adyen-checkout__label {
  font-family: var(--font-family, inherit);
  &__text {
    font-size: var(--font-size-md);
    font-weight: 500;
    margin-bottom: 0.5rem;
    color: var(--input-label-color, var(--primary-color, @text-color-black));
  }

  &--focused {
    .adyen-checkout__label__text {
      color: var(--input-label-color, var(--primary-color, @text-color-black));
    }
  }
}

.adyen-checkout__label--focused + .adyen-checkout__input-wrapper {
  border: var(--input-border, 1px solid @color-grey);
  box-shadow: none;
}

// Input fields
.adyen-checkout__input-wrapper {
  height: 45px;
  font-size: 16px;
  font-weight: 400;
  border: var(--input-border, 1px solid @color-grey);
  background-color: @color-white;
  border-radius: var(--input-border-radius, var(--border-radius, 8px));
  box-shadow: none;

  .adyen-checkout__input {
    color: @text-color-black;
  }

  &::placeholder {
    font-weight: 400;
    font-size: 16px;
    color: #bbbec5;
  }

  &:focus-within {
    border: var(--input-hover-border, 1px solid #d9dadc);
    box-shadow: none;

    &:hover {
      border: var(--input-hover-border, 1px solid #d9dadc);
      box-shadow: none;
    }
  }

  &:focus {
    border: var(--input-hover-border, 1px solid #d9dadc);
    box-shadow: none;

    &:hover {
      border: var(--input-hover-border, 1px solid #d9dadc);
      box-shadow: none;
    }
  }

  &:active {
    border: var(--input-hover-border, 1px solid #d9dadc);
    box-shadow: none;

    &:hover {
      border: var(--input-hover-border, 1px solid #d9dadc);
      box-shadow: none;
    }
  }

  &--focus {
    border: var(--input-hover-border, 1px solid #d9dadc);
    box-shadow: none;
  }

  &:hover {
    border: var(--input-hover-border, 1px solid #d9dadc);
    box-shadow: none;
  }
}

// Checkbox label
.adyen-checkout__store-details {
  margin-top: calc(24px + var(--checkbox-label-margin-top, 1px));
  background: transparent;
  border: none;
  padding: 0;
}

.adyen-checkout__checkbox__label {
  font-size: var(--font-size-md);
  font-weight: 500;
  margin-bottom: 0.5rem;
  color: var(--checkbox-color, var(--primary-color, @text-color-black));
}

.adyen-checkout__checkbox__label:after {
  border: var(--checkbox-border, 1px solid @color-grey) !important;
  box-shadow: none !important;
  margin-top: calc(2px - var(--checkbox-label-margin-top, 1px));
}

// Adjust checkbox hover styles
.adyen-checkout__checkbox__input:hover {
  + .adyen-checkout__checkbox__label:after {
    border: var(--checkbox-hover-border, 1px solid #e6ebf1) !important;
    box-shadow: none !important;
  }
}

.adyen-checkout__checkbox__input {
  // Checkbox
  + .adyen-checkout__checkbox__label:after {
    height: 18px;
    width: 18px;
    background-color: var(--checkbox-background, #fff);
    border-radius: var(--checkbox-border-radius, var(--border-radius, 3px));
    border: var(--checkbox-border, 1px solid #e6ebf1);
  }

  // Checkmark icon
  + .adyen-checkout__checkbox__label:before {
    top: calc(5px - var(--checkbox-label-margin-top, 1px));
    left: 2px;
    border-radius: var(--checkbox-border-radius, var(--border-radius, 3px));
    border-right-color: var(
      --checkbox-checked-color,
      var(--checkbox-color, #fff)
    );
    border-bottom-color: var(
      --checkbox-checked-color,
      var(--checkbox-color, #fff)
    );
  }
}

.adyen-checkout__checkbox__input:checked
  + .adyen-checkout__checkbox__label:after {
  transition: background-color 0.2s ease;
  background-color: var(
    --checkbox-checked-background,
    var(--checkbox-background, #0a0a0a)
  );
}

// Error messages
.adyen-checkout-contextual-text--error {
  margin-top: 2px;
  font-size: var(--font-size-xs);
  color: var(--error-color, @color-red);
}

// Info messages
.adyen-checkout-contextual-text {
  margin-top: 2px;
  font-size: var(--font-size-xs);
  color: var(--input-label-color, var(--primary-color, @text-color-black));
}

.adyen-checkout__icon {
  vertical-align: inherit;
}

// Supported cards images
.adyen-checkout__card__brands {
  justify-content: flex-end;
  margin-bottom: 0px;

  .adyen-checkout__card__brands__brand-wrapper {
    img {
      position: absolute;
    }
  }
}

// Override styles to make expiration date full width when CVC is hidden
.adyen-checkout__field__exp-date {
  width: 100%;
  margin-right: 0 !important;
}

.adyen-checkout__card__holderName {
  margin-top: 8px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
}

.adyen-checkout__field__cvc {
  width: 100%;
  margin-left: 16px !important;
}

.adyen-checkout__field--error .adyen-checkout__input-wrapper {
  border-color: var(--error-color, @color-red) !important;
}

.adyen-checkout-input__inline-validation--invalid {
  color: var(--error-color, @color-red) !important;
}

/* Hide info texts on mobile */
@media screen and (max-width: 600px) {
  .adyen-checkout-contextual-text {
    display: none;
  }

  .adyen-checkout__card__holderName {
    margin-top: 24px;
  }
}
