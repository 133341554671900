@import "core/style/style.less";

#seperator-component {
  display: flex;
  align-items: center;

  .seperator-line {
    border-top: var(--box-seperator, 2px solid @color-grey);
    width: 100%;
  }

  .seperator-text {
    font-size: var(--font-size-sm);
    color: var(--primary-color, @text-color-black);
    font-weight: 400;
    margin-left: @spacing-md;
    margin-right: @spacing-md;
  }
}
