#root {
  height: 100%;
  font-family: var(--font-family, "Open sans", sans-serif);
  background: var(--background) no-repeat center/cover;
  overflow-x: hidden;
}

// Global variables (colors, spacings, etc.)
@import "./settings";

// Useful global classes
@import "./layout/display";
