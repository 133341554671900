@import "core/style/settings";

#checkout-screen {
  width: 100%;
  height: 100%;
  display: flex;
  margin-left: @spacing-md;
  margin-right: @spacing-md;

  .card {
    height: 200px;
    margin-top: @spacing-xs;
    margin-bottom: @spacing-lg;
    display: var(--card, block);

    &.--has-information-screen {
      margin-top: @spacing-lg;
    }
  }

  .checkout-content {
    margin-top: @spacing-md;
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 100%;

    .adyen-pay-now-button {
      margin-top: calc(24px - var(--checkbox-label-margin-top, 0px));
    }

    .loading-screen {
      width: 100%;
      height: 314px;
      display: flex;
      justify-content: center;
      align-items: center;

      .loading-spinner {
        width: 40px;
        height: 40px;
        position: relative;

        &:after,
        &:before {
          animation: rotate 1s cubic-bezier(0.785, 0.135, 0.15, 0.86) infinite;
          border: 4px solid transparent;
          border-radius: 50%;
          content: "";
          display: block;
          height: 100%;
          left: 0;
          position: absolute;
          top: 0;
          width: 100%;
        }

        &:after {
          border-color: transparent;
          border-top-color: var(--primary-color, @color-black);
        }

        &:before {
          border-color: var(--primary-color, @color-black);
          border-top-color: transparent;
          opacity: 0.1;
        }
      }
    }

    .card-container {
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }
}

@media only screen and (max-width: 1100px) {
  #checkout-screen {
    margin-top: 0px;
    padding-top: 0px;
    margin-left: 0px;
    margin-right: 0px;
  }
}

@media only screen and (max-height: 800px) and (min-width: 1100px) {
  #checkout-screen {
    .payment-method-credit-card {
      margin-top: @spacing-lg;
    }

    .card-container {
      .card {
        display: none;
      }
    }
  }
}
