@import "core/style/style.less";

#payment-screen {
  width: 100%;
  padding-top: @spacing-lg;
  padding-left: @spacing-md;
  padding-right: @spacing-md;

  &.--has-information-screen {
    padding-top: 0px;
  }

  .title {
    font-weight: 500;
    color: var(--primary-color, @text-color-black);
    font-size: var(--font-size-lg);
    margin-bottom: @spacing-md;
  }

  .text {
    text-align: justify;
    hyphens: auto;
    word-spacing: -0.025em;
    font-weight: 400;
    color: var(--primary-color, @text-color-black);
    font-size: var(--font-size-md);
    margin-bottom: @spacing-lg;
  }

  .payment-methods-container {
    display: flex;
    flex-direction: column;
    padding-top: @spacing-lg;

    .payment-method {
      margin-bottom: @spacing-md;
      width: 100%;

      &:last-of-type {
        margin-bottom: 0px;
      }
    }

    .seperator-container {
      margin-top: @spacing-md;
      margin-bottom: @spacing-md;
    }

    .paypal {
      background-color: @color-yellow;
      border: none;
    }

    .pay-by-bank {
      .adyen-checkout__button--standalone {
        height: 45px;
        background-color: var(--pay-by-bank-button-background, #e6ebf1);
        border: var(--pay-by-bank-button-border, 1px solid #e6ebf1);
        border-radius: var(
          --pay-by-bank-button-border-radius,
          var(--border-radius, 8px)
        );
        box-shadow: var(--pay-by-bank-button-box-shadow, none);

        &:hover {
          background-color: var(
            --pay-by-bank-button-hover-background,
            var(--pay-by-bank-button-background, #e6ebf1)
          );
          border: var(
            --pay-by-bank-button-hover-border,
            var(--pay-by-bank-button-border, 1px solid #e6ebf1)
          );

          .adyen-checkout__button__text {
            color: var(
              --pay-by-bank-button-hover-color,
              var(--pay-by-bank-button-color, #3c3d3d)
            );
          }
        }

        .adyen-checkout__button__text {
          font-weight: 500;
          font-size: var(--font-size-md);
          color: var(--pay-by-bank-button-color, #3c3d3d);
        }
      }
    }
  }

  #googlepay-container {
    width: 100%;

    #gpay-button-online-api-id {
      outline: none;
      height: 45px;
      width: 100%;
    }

    .gpay-card-info-container {
      border: var(--google-pay-button-border, "none");
      border-radius: var(
        --google-pay-button-border-radius,
        var(--border-radius, 8px)
      );
      box-shadow: var(--google-pay-button-box-shadow, "none");

      &:hover {
        border: var(
          --google-pay-button-hover-border,
          var(--google-pay-button-border, "none")
        );
      }
    }

    .gpay-card-info-animation-container {
      border-radius: var(
        --google-pay-button-border-radius,
        var(--border-radius, 8px)
      );
    }
  }

  #applepay-container {
    width: 100%;
    height: 45px;
    margn-bottom: 0px;

    // Hide apple pay, when there is an error (e.g. not available)
    &.error {
      display: none;
    }

    apple-pay-button {
      --apple-pay-button-height: 45px;
      /* Both border-radius and --apple-pay-button-border-radius is needed! */
      --apple-pay-button-border-radius: var(
        --apple-pay-button-border-radius,
        var(--border-radius, 8px)
      );
      border-radius: calc(
        var(--apple-pay-button-border-radius, var(--border-radius, 8px)) + 4px
      );
      border: var(--apple-pay-button-border, "none");
      box-shadow: var(--apple-pay-button-box-shadow, "none");

      &:hover {
        border: var(
          --apple-pay-button-hover-border,
          var(--apple-pay-button-border, "none")
        );
      }
    }
  }
}

@media only screen and (max-width: 1100px) {
  #payment-screen {
    padding-top: @spacing-lg;
    padding-right: 0px;
    padding-left: 0px;

    &.--has-information-screen {
      padding-top: 0px;
    }
  }
}
