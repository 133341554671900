@import "core/style/settings";

#loading-screen {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--primary-background, #fff);

  .loading-spinner {
    width: 40px;
    height: 40px;
    position: relative;
    
    &:after, &:before {
      animation: rotate 1s cubic-bezier(.785,.135,.15,.86) infinite;
      border: 4px solid transparent;
      border-radius: 50%;
      content: "";
      display: block;
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }

    &:after {
      border-color: transparent;
      border-top-color: var(--primary-color, @color-black);
    }

    &:before {
      border-color: var(--primary-color, @color-black);
      border-top-color: transparent;
      opacity: 0.1;
    }
  }
}
