@import "core/style/settings";

#terms-screen {
  .terms-screen-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    max-width: var(--container-max-width, 1200px);
    margin: 0 auto;

    .main-container {
      display: flex;
      justify-content: center;
      flex-grow: 1;
      height: 100%;
      width: 100%;
      /* background: var(--primary-background) no-repeat center/cover; */

      .container {
        display: flex;
        flex-direction: column;
        max-width: 600px;
        width: 100%;

        .header-container {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          margin-top: 32px;
          margin-right: 24px;
        }

        .terms-container {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          padding: var(
            --information-container-padding,
            @spacing-sm 0px 0px 0px
          );
          margin: var(--information-container-margin, 32px 0px 0px 0px);
          padding-left: @spacing-md;
          padding-right: @spacing-md;
          padding-bottom: @spacing-md;
          margin-left: 0px;
          margin-right: 0px;
          margin-bottom: 0px;
          border-radius: var(--border-radius, 6px);
          background: var(--primary-background, @information-screen-background)
            no-repeat center/cover;

          .title {
            text-align: left;
            margin-top: 0px;
            font-size: var(--font-size-lg);
            font-weight: 600;
            color: var(--primary-color, @text-color-black);
          }

          .text {
            text-align: justify;
            hyphens: auto;
            word-spacing: -0.03em;
            margin-top: @spacing-sm;
            font-size: var(--font-size-sm);
            color: var(--primary-color, @text-color-black);

            a {
              text-decoration: none;
              color: var(--primary-color, @text-color-black);

              &:hover {
                text-decoration: underline;
              }
            }

            ul,
            ol {
              list-style-type: none;
            }
          }
        }

        .footer {
          display: flex;
          justify-content: space-between;
          padding-top: @spacing-md;
          margin-top: @spacing-sm;
          margin-bottom: 32px;
          margin-right: @spacing-md;
          margin-left: @spacing-md;
          margin-left: @spacing-md;

          .footer-content {
            display: flex;
            align-items: center;
            color: var(--primary-color, @text-color-black);
            font-size: var(--font-size-xs);
            font-weight: 400;

            .seperator {
              width: 1px;
              height: 12px;
              background-color: var(--primary-color, @text-color-black);
              margin-left: @spacing-xs;
              margin-right: @spacing-xs;
            }

            .powered-by svg {
              margin-left: @spacing-xs;
            }

            .links {
              margin-right: @spacing-xs;
              cursor: pointer;

              &:hover {
                text-decoration: underline;
              }

              &.active {
                cursor: default;
                text-decoration: underline;
              }
            }
          }
        }
      }
    }
  }

  .security-icon {
    display: flex;
    justify-content: flex-end;
    margin-top: calc(-65px - 1.5rem);
    margin-right: 1.5rem;
  }
}

@media only screen and (max-width: 750px) {
  #terms-screen {
    .security-icon {
      display: none;
    }
  }
}

@media only screen and (max-width: 400px) {
  #terms-screen {
    .terms-screen-container {
      .container {
        .footer {
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-top: 0px !important;
          padding-top: 24px !important;

          .footer-content {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-bottom: 0px;

            .seperator {
              display: none;
            }

            .links {
              margin-top: 0.25rem;
              margin-right: 0.25rem;
              margin-left: 0px;
              cursor: pointer;

              &:last-child {
                margin-right: 0px;
              }
            }
          }
        }
      }
    }
  }
}
