@import "core/style/settings.less";

#button-component {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px;
  font-weight: 500;
  font-size: var(--font-size-md);
  height: 45px;
  cursor: pointer;
  position: relative;

  .loading-spinner {
    width: 20px;
    height: 20px;
    position: absolute;

    @keyframes rotate {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }

    &:after,
    &:before {
      animation: rotate 1s cubic-bezier(0.785, 0.135, 0.15, 0.86) infinite;
      border: 4px solid transparent;
      border-radius: 50%;
      content: "";
      display: block;
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }
  }

  .button-text {
    &.is-loading {
      visibility: hidden;
    }
  }

  &.is-expandable {
    transition: width 0.2s ease-in-out;
    padding: 10px;
    min-width: 50px;
    width: 50px;

    .icon-left {
      margin-right: 0px;

      &.is-loading {
        visibility: hidden;
      }
    }

    .icon-right {
      margin-left: 0px;

      &.is-loading {
        visibility: hidden;
      }
    }

    .button-text {
      opacity: 0;
      transition: opacity 0.1s ease-in-out;

      &.is-loading {
        visibility: hidden;
      }
    }
  }

  &.full-width {
    width: 100%;
  }

  .icon-left {
    margin-right: @spacing-sm;

    &.is-loading {
      visibility: hidden;
    }
  }

  .icon-right {
    margin-left: @spacing-sm;

    &.is-loading {
      visibility: hidden;
    }
  }

  &.is-expanded {
    width: 100%;
    padding: 15px;

    .button-text {
      opacity: 1;
      transition-delay: 0.1s; /* Slight delay to ensure the button expands first */

      &.is-loading {
        visibility: hidden;
      }
    }
  }
}
